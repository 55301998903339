"use client";
import { useEffect } from "react";
import { useRouter } from "next/navigation";

export default function NotFound() {
    const router = useRouter();
    const params = typeof window !== "undefined" ? window.location.search : "";
    useEffect(() => {
        const redirectUrl = "/" + params;
        router.push(redirectUrl);
    }, [router, params]);

    return <></>;
}
